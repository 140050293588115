<template>
  <ul class="menu-nav">
    <li class="menu-section"
        v-if="hasPermission(['ADMIN'])">
      <h4 class="menu-text">Generales</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      v-if="hasPermission(['ADMIN'])"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('masters') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Maestros</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Maestros</span>
            </span>
          </li>

          <!-- COLOR -->
          <router-link
            to="/masters/color/list"
            v-if="hasPermission(['ADMIN'])"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Colores</span>
              </a>
            </li>
          </router-link>
          
          <!-- MEASURE UNIT -->
          <router-link
            to="/masters/measure-unit/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Unidades de medida</span>
              </a>
            </li>
          </router-link>

          <!-- EQUIVALENT UNIT -->
          <router-link
            to="/masters/equivalent-unit/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Unidades Equivalentes </span>
              </a>
            </li>
          </router-link>

          <!-- UNIT TYPE-->
          <router-link
            to="/masters/unit-type/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de Unidad</span>
              </a>
            </li>
          </router-link>
          
          <!-- BRAND -->
          <router-link
            to="/masters/brand/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['ADMIN'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Marcas</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>
    <li class="menu-section" v-if="hasPermission(['ADMIN'])">
      <h4 class="menu-text">Ingenieria</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('materials') }"
      v-if="hasPermission(['ADMIN'])"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Materiales</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Materiales</span>
            </span>
          </li>

          <!-- BRAND -->
          <router-link
            to="/materials/materials-master/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Maestro de materiales</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('customer-quote') }"
      v-if="hasPermission(['ADMIN'])"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Cotización de clientes</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Cotización de clientes</span>
            </span>
          </li>

          <!-- QUOTE REQUEST -->
          <router-link
            to="/customer-quote/quote-request/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Solicitudes de cotización</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('maintenance') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Mantenimientos</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Mantenimientos</span>
            </span>
          </li>

          <!-- CATEGORY -->
          <router-link
            to="/maintenances/category/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
              v-if="hasPermission(['ADMIN'])"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Categoría</span>
              </a>
            </li>
          </router-link>

          <!-- CLIENT -->
          <router-link
            to="/maintenances/client/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['ADMIN'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cliente</span>
              </a>
            </li>
          </router-link>

          <!-- ITEM SPEC TECH -->
          <router-link
            to="/maintenances/item-spec-tech/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['ADMIN'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Espec. Téc. item</span>
              </a>
            </li>
          </router-link>

          <!-- PROVIDER -->
          <router-link
            to="/maintenances/provider/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['ADMIN'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Proveedor</span>
              </a>
            </li>
          </router-link>

          <!-- SUB-PROJECT -->
          <router-link
            to="/maintenances/subproject/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['ADMIN'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Partida</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('project') }"
      v-if="hasPermission(['ADMIN'])"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Proyectos</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Proyectos</span>
            </span>
          </li>
          <!-- PROJECT -->
          <router-link
            to="/project/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lista de Proyectos</span>
              </a>
            </li>
          </router-link>
          <!-- PROJECT -->
          <router-link
            to="/project/wizard"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Nuevo proyecto</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- BEGIN RECURSOS HUMANOS -->
    <li class="menu-section"
        v-if="hasPermission(['ADMIN'])">
      <h4 class="menu-text">Recursos Humanos</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('human-resources') }"
      v-if="hasPermission(['RECURSOS_HUMANOS'])"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-doc"></i>
        <span class="menu-text">Maestros</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Maestros</span>
            </span>
          </li>

          <!-- USER -->
          <router-link
            to="/human-resources/user/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['RECURSOS_HUMANOS'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Usuario</span>
              </a>
            </li>
          </router-link>

          <!-- DOCUMENTS -->
          <router-link
            to="/human-resources/documents/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['RECURSOS_HUMANOS'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Documentos</span>
              </a>
            </li>
          </router-link>

          <!-- ENTERPRISE -->
          <router-link
            to="/human-resources/enterprise/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission(['RECURSOS_HUMANOS'])"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Empresa</span>
              </a>
            </li>
          </router-link>

          <!-- PERIOD -->
          <router-link
            :to="{ name: 'human_resurces_period' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Periodos</span>
              </a>
            </li>
          </router-link>

          <!-- PROJECT -->
          <router-link
            :to="{ name: 'maintenances_enterprise_list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Empleados</span>
              </a>
            </li>
          </router-link>
          
        </ul>
      </div>
    </li>

    <!-- GESTIÓN DE DOCUMENTOS -->

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('human-resources') }"
      v-if="hasPermission(['RECURSOS_HUMANOS'])"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-doc"></i>
        <span class="menu-text">Gestión de documentos</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Gestión de documentos</span>
            </span>
          </li>
          <!-- BOLETAS DE PAGO -->
          <router-link
            :to="{ name: 'human_resources_boleta_de_pago' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Boletas de pago</span>
              </a>
            </li>
          </router-link>

          <!-- GRATIFICACIONES -->
          <router-link
            :to="{ name: 'human_resources_gratificacion' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Gratificaciones</span>
              </a>
            </li>
          </router-link>

          <!-- MEMORANDUM -->
          <router-link
            :to="{ name: 'human_resources_memorandum' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Memorándum</span>
              </a>
            </li>
          </router-link>

          <!-- CONTRATOS -->
          <router-link
            :to="{ name: 'human_resources_contrato' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Contratos</span>
              </a>
            </li>
          </router-link>

          <!-- OTROS -->
          <router-link
            to="/human-resources/employee-documents"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Otros</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section"
        v-if="hasPermission(['ADMIN'])">
      <h4 class="menu-text">OTROS</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>


    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('store') }"
      v-if="hasPermission(['ADMIN'])"
    >
      <!-- STORE -->
      <router-link
        to="/store/list"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          :href="href"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
          class="menu-link"
          @click="navigate"
        >
          <i class="menu-icon flaticon-web"></i>
          <span class="menu-text">Almacén</span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { hasPermission } from "../../../utils";

export default {
  name: "KTMenu",
  methods: {
    hasPermission(roles) {
      return hasPermission(roles);
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
