import { getToken } from "./core/services/jwt.service";
import jwtDecode from "jwt-decode";

export const hasPermission = (render_roles) => {
  // array
  if (!getToken()) {
    return false;
  }
  let token = getToken();
  let decoded_token = jwtDecode(token);
  let xban = false;
  if ("roles" in decoded_token) {
    for (let i of decoded_token["roles"]) {
      for (let j of render_roles) {
        if (j === i) {
          xban = true;
        }
      }
    }
  } else {
    return false;
  }
  return xban;
};
